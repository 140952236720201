import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Avatar,
  Burger,
  Container,
  Group,
  Header as MHeader,
  Menu,
  Paper,
  Text,
  Tooltip,
  Transition,
  UnstyledButton,
  createStyles,
} from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { Icon3dCubeSphere, IconChevronDown, IconLogout, IconSettings } from '@tabler/icons'
import { DataHelper } from '@testsa/helpers/Data.helper'
import { StylesHelper } from '@testsa/helpers/Styles.helper'
import styles from '@testsa/styles/layout/Header.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import type {
  ClientUserApiDTOAllOf,
  ClientUserPermissionsAttributesApiDTO,
  GetApiV1UserMe200,
  UserPermissionsAttributesApiDTO,
} from 'src/codegen/generated/Api.schemas'

import { AuthHelper } from '../helpers/Auth.helper'
import { MenuHelper, type MenuLink } from '../helpers/Menu.helper'

const HEADER_HEIGHT = 60

const useStyles = createStyles(theme => ({
  user: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },
  userActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
  },
  dropdown: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}))

type HeaderProps = {
  links: MenuLink[]
  user?: GetApiV1UserMe200 & ClientUserApiDTOAllOf
}

export function Header({ links, user }: HeaderProps) {
  const [opened, { toggle, close }] = useDisclosure(false)
  const { classes, cx, theme } = useStyles()
  const { t } = useTranslation('common')
  const tabletScreen = useMediaQuery(StylesHelper.TABLET)

  const [active, setActive] = useState<MenuLink>()
  const [userMenuOpened, setUserMenuOpened] = useState(false)

  const env = process.env.NEXT_PUBLIC_ENV ?? 'development'
  const rootHref = useMemo(
    () =>
      user?.type === 'clientUser' ? `/dashboards/organisations/${user?.organization?.id}` : '/',
    [user?.type, user?.organization?.id],
  )

  useEffect(() => setActive(MenuHelper.getActiveLink(links)), [links])

  const hasPermission = useCallback(
    (menuLink: MenuLink) => {
      {
        const permissions = DataHelper.permissionsToArray<
          UserPermissionsAttributesApiDTO | ClientUserPermissionsAttributesApiDTO
        >(user?.permissions ?? {})
        if (!menuLink.permission) {
          return true
        }
        if (permissions.includes(menuLink.permission ?? '')) {
          return true
        }
        if (menuLink.permission === 'canManageProjects') {
          return true
        }

        return false
      }
    },
    [user?.permissions],
  )

  const items = useMemo(
    () =>
      links.map(l => {
        if (hasPermission(l) === false) {
          return (
            <Tooltip
              key={l.label}
              label={t('generics.notEnoughAccess')}
              width={280}
              multiline
              withArrow
            >
              <Text className={styles.linkDisabled} span>
                {l.icon || ''}
                {l.label}
              </Text>
            </Tooltip>
          )
        }

        if (l.subLinks && l.subLinks.length > 0) {
          return (
            <Menu
              width={260}
              position="bottom-end"
              transition="pop-top-right"
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
              key={l.label}
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(styles.link, classes.link, {
                    [classes.linkActive]:
                      typeof active !== 'undefined' &&
                      l.subLinks.find(sl => active.subLinks?.find(asl => asl.link === sl.link)),
                  })}
                >
                  {l.icon ?? ''}
                  {l.label}
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                {l.subLinks.map(sl => (
                  <span key={sl.label}>
                    <Link href={sl.link ?? ''}>
                      <Menu.Item icon={sl.icon}>{sl.label}</Menu.Item>
                    </Link>
                    {sl.divider && <Menu.Divider />}
                  </span>
                ))}
              </Menu.Dropdown>
            </Menu>
          )
        }

        return (
          <Link
            href={l.link ?? ''}
            className={cx(styles.link, classes.link, {
              [classes.linkActive]: active?.link === l.link,
            })}
            key={l.label}
            onClick={() => {
              setActive(l)
              close()
            }}
          >
            {l.icon ?? ''}
            {l.label}
          </Link>
        )
      }),
    [active, classes.link, classes.linkActive, close, cx, links, t, hasPermission],
  )

  const mobileItems = useMemo(
    () =>
      links.map(l => {
        if (hasPermission(l) === false) {
          return (
            <Tooltip
              key={l.label}
              label={t('generics.notEnoughAccess')}
              width={280}
              multiline
              withArrow
            >
              <Text className={styles.linkDisabled} span>
                {l.icon ?? ''}
                {l.label}
              </Text>
            </Tooltip>
          )
        }

        if (l.subLinks && l.subLinks.length > 0) {
          return l.subLinks.map(sl => (
            <Link
              href={sl.link ?? ''}
              className={cx(styles.link, classes.link)}
              key={sl.label}
              onClick={() => {
                setActive(l)
                close()
              }}
            >
              {sl.icon ?? ''}
              {sl.label}
            </Link>
          ))
        }

        return (
          <Link
            href={l.link ?? ''}
            className={cx(styles.link, classes.link, {
              [classes.linkActive]: active?.link === l.link,
            })}
            key={l.label}
            onClick={() => {
              setActive(l)
              close()
            }}
          >
            {l.icon || ''}
            {l.label}
          </Link>
        )
      }),
    [active?.link, classes.link, classes.linkActive, close, cx, links, t, hasPermission],
  )

  return (
    <MHeader height={HEADER_HEIGHT} mb="xl" className={styles.root}>
      <Container className={styles.header} maw={tabletScreen ? '95%' : '85%'}>
        <div className={styles.logo}>
          <Link href={rootHref}>
            <Image
              alt="TEST logo"
              src={`/assets/${env}/logo.png`}
              fill
              sizes="100vw"
              style={{
                objectFit: 'contain',
              }}
            />
          </Link>
        </div>
        <Group>
          {user?.type === 'user' && (
            <Group spacing={5} className={classes.links}>
              {items}
            </Group>
          )}

          <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />

          <Menu
            width={260}
            position="bottom-end"
            transition="pop-top-right"
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(styles.user, classes.user, {
                  [classes.userActive]: userMenuOpened,
                })}
              >
                <Group spacing={7}>
                  <Avatar radius="xl" size={25} />
                  <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                    {user?.firstName}
                    &nbsp;
                    {user?.lastName}
                  </Text>
                  <IconChevronDown size={12} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>{t('words.settings')}</Menu.Label>
              <Link href="/account">
                <Menu.Item icon={<IconSettings size={14} stroke={1.5} />}>
                  {t('account._title', { ns: 'pages' })}
                </Menu.Item>
              </Link>
              {user?.type === 'user' && (
                <Link href="/resources">
                  <Menu.Item icon={<Icon3dCubeSphere size={14} stroke={1.5} />}>
                    {t('words.resource_other')}
                  </Menu.Item>
                </Link>
              )}
              <Menu.Divider />
              <Menu.Item
                icon={<IconLogout size={14} stroke={1.5} color={theme.colors.red[6]} />}
                onClick={() => AuthHelper.logout()}
              >
                {t('words.logout')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {trStyles => (
            <Paper className={cx(styles.dropdown, classes.dropdown)} withBorder style={trStyles}>
              {mobileItems}
              <Link href="/account" className={cx(styles.link, classes.link)}>
                <IconSettings {...MenuHelper.ICON_PROPS} />
                {t('account._title', { ns: 'pages' })}
              </Link>
              <Link
                href=""
                className={cx(styles.link, classes.link)}
                onClick={() => AuthHelper.logout()}
              >
                <IconLogout color={theme.colors.red[6]} {...MenuHelper.ICON_PROPS} />
                {t('words.logout')}
              </Link>
            </Paper>
          )}
        </Transition>
      </Container>
    </MHeader>
  )
}
