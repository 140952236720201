import { Box, Center, Flex, Paper, createStyles } from '@mantine/core'
import { AuthHelper } from '@testsa/helpers/Auth.helper'
import styles from '@testsa/styles/layout/LoginLayout.module.scss'
import { getCookie } from 'cookies-next'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { Head } from './Head'

const useStyles = createStyles(theme => ({
  sidebar: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },
}))

export const LoginLayout = ({ children }: { children: JSX.Element }) => {
  const router = useRouter()
  const { classes, cx } = useStyles()
  const env = process.env.NEXT_PUBLIC_ENV || 'development'
  const token = getCookie(AuthHelper.AUTH_COOKIE_NAME)

  if (token) {
    router.push('/')

    return <></>
  }

  return (
    <>
      <Head title="Login | TEST SA" description="TEST SA" />
      <Flex className={styles.root}>
        <Paper className={cx(classes.sidebar, styles.sidebar)} radius={0} p={30}>
          <Box className={styles.logo}>
            <Image alt="TEST logo" src={`/assets/${env}/logo.png`} fill />
          </Box>
          {children}
        </Paper>
        <Center className={styles.imageWrapper}>
          <Image
            alt="TEST logo"
            src={'/assets/images/enquete-terrain.png'}
            width={427}
            height={432}
          />
        </Center>
      </Flex>
    </>
  )
}
